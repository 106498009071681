import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot }                           from '@angular/router';
import { ApiService } from "./api.service";

@Injectable({
    providedIn: 'root'
})


@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
        private apiService : ApiService,
        private router : Router
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) : boolean {
        if(window.localStorage.getItem('access_token') !== null) {
            console.log(this.apiService.isLoggedIn());
            return true;
        } else {
            this.router.navigate(["/signin"]);
        }
        return false;
    }
}
