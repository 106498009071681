import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-radio-buttons',
  templateUrl: './radio-buttons.component.html',
  styleUrls: ['./radio-buttons.component.css']
})
export class RadioButtonsComponent implements OnInit {

  @Input()
  public set radioBtnInputVals(data) {
    if (data) {
      this._radioBtnInputVals = data;
    }
  }
  public get radioBtnInputVals() {
    return this._radioBtnInputVals;
  }

  public extraParams: string;
  public _radioBtnInputVals: Array<{}>;

  @Output('extraParamUserInput') extraParamUserInput = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  public onRadionBtnClick(value: string) {
    this.extraParamUserInput.emit(value);
  }
}
