// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // tslint:disable-next-line:quotemark
    apiUrl: 'https://akshayaun-bebb9de9-eval-prod.apigee.net/pyservice_dev_clone',
    // "http://dev.kornerstoneanalytics.com:5006", // when using without proxy
            apiUrl1: 'https://a7a6367d1827.ngrok.io',
    clientAccessToken: 'ea902e18e536405780a9aad3bfc25e9f'
};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
