import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ApiService} from '../services/api.service';
import {Router} from '@angular/router';

import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.css']
})

export class SignUpComponent implements OnInit {

    public registerForm: FormGroup;
    public submitted = false;
    public isEmailExits = false;
    public isErrorMsg = false;
    public isSuccessMsg = false;
    roles;
    languages;
    @Input() showNavbars = true;
    @Output() closeSignUp = new EventEmitter();
    @Output() openLoginPage = new EventEmitter();

    constructor(private formBuilder: FormBuilder, private apiService: ApiService, private spinner: NgxSpinnerService,
                private router: Router) {
    }

    get f() {
        return this.registerForm.controls;
    }

    ngOnInit() {
        this.registerForm = this.formBuilder.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
            confirmPassword: ['', Validators.required],
            phone: ['', [Validators.minLength(10)]],
            gender: ['',],
            location: ['', []],
            company: ['',],
            exp: ['',],
            role: ['',],
            language: ['',],
            send_mail: false
        }, {validator: this.passwordMatchValidator});
        this.getAllRoles();
        this.languages = [{'description': 'Unknown', 'name': 'No Knowledge', 'id': 0}, {
            'description': 'Python',
            'name': 'Python',
            'id': 1
        }, {'description': '.NET', 'name': '.NET', 'id': 2}, {
            'description': 'Javascript',
            'name': 'Javascript',
            'id': 3
        }, {'description': 'jQuery', 'name': 'jQuery', 'id': 4}, {
            'description': 'SQL',
            'name': 'SQL',
            'id': 5
        }, {'description': 'Angular JS', 'name': 'Angular JS', 'id': 6}, {
            'description': 'Node JS',
            'name': 'Node JS',
            'id': 7
        }, {'description': 'C#', 'name': 'C#', 'id': 8}, {'description': 'C++', 'name': 'C++', 'id': 9}, {
            'description': 'Perl',
            'name': 'Perl',
            'id': 10
        }, {'description': 'PHP', 'name': 'PHP', 'id': 11}, {'description': 'C', 'name': 'C', 'id': 12}, {
            'description': 'Shell',
            'name': 'Shell',
            'id': 13
        }, {'description': 'TCL', 'name': 'Tcl', 'id': 14}, {'description': 'VB', 'name': 'VB', 'id': 15}, {
            'description': 'COBOL',
            'name': 'COBOL',
            'id': 16
        }, {'description': 'Java', 'name': 'Java', 'id': 17}, {
            'description': 'HTML',
            'name': 'HTML',
            'id': 18
        }, {'description': 'Laravel PHP', 'name': 'Laravel PHP', 'id': 19}, {
            'description': 'Lisp',
            'name': 'Lisp',
            'id': 20
        }, {'description': 'Objective C', 'name': 'Objective C', 'id': 21}, {
            'description': 'XML',
            'name': 'XML',
            'id': 22
        }, {'description': 'Drupal PHP', 'name': 'Drupal PHP', 'id': 23}, {
            'description': 'AJAX',
            'name': 'AJAX',
            'id': 24
        }, {'description': 'Laravel Web', 'name': 'Laravel Web', 'id': 25}, {
            'description': 'Go',
            'name': 'Go',
            'id': 26
        }, {'description': 'Not Applicable', 'name': 'NAPP', 'id': 27}, {'description': 'R', 'name': 'R', 'id': 28}];
        // this.getAllLanguages();
    }

    /**
     * onSignUp
     */

    public onSignUp() {
        this.submitted = true;
        if (this.registerForm.invalid) {
            return;
        }
        this.spinner.show();
        this.apiService.signUp(this.registerForm.value)
            .subscribe(
                (responce) => {
                    this.spinner.hide();
                    console.log('responce', responce);
                    if (responce['status'] = 200 && responce['user_id'] !== null) {
                        this.isSuccessMsg = true;
                        this.isErrorMsg = false;
                        this.isEmailExits = false;

                        setTimeout(() => {
                            this.isSuccessMsg = false;
                            this.router.navigateByUrl('/signin');
                        }, 3000);
                    } else {
                        this.isEmailExits = true;
                    }
                },
                (err) => {
                    this.spinner.hide();
                    this.isSuccessMsg = false;
                    this.isErrorMsg = true;
                    this.isEmailExits = true;
                    setTimeout(() => {
                        this.isErrorMsg = false;
                    }, 4000);
                }
            );
    }


    /**
     * close error message
     */

    public closeErrorMsg() {
        this.isErrorMsg = false;
    }

    /**
     * close success message
     */

    public closeSuccessMsg() {
        this.isSuccessMsg = false;
    }

    passwordMatchValidator(g: FormGroup) {
        return g.get('password').value === g.get('confirmPassword').value
            ? null : {'mismatch': true};
    }

    getAllRoles() {
        const self = this;
        this.apiService.getUserRoles().subscribe(res => {
            self.roles = res;
        }, error => {
            // TODo handle errors.
        });
    }

    getAllLanguages() {
        const self = this;
        const user = {
            email: 'bvasanthakrishna@gmail.com',
            password: 'bharathi'
        };

        this.apiService.login(user).subscribe(res => {
            localStorage.setItem('access_token', res['access_token']);
            this.apiService.getLanguages().subscribe(lang => {
                self.languages = lang;
            }, err => {

            });
        });

    }

    closeClick() {
        this.closeSignUp.emit();
    }

    openLogin() {
        this.openLoginPage.emit();
    }
}
