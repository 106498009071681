import {Component, OnInit, ViewEncapsulation, ViewChild, ElementRef} from '@angular/core';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {HighlightTag} from 'angular-text-input-highlight';

import {ApiService} from '../services/api.service';
import {FormGroup, FormBuilder} from '@angular/forms';
import {DEFAULTMESSAGES} from '../models/messages.model';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-landing',
    templateUrl: './landing.component.html',
    styleUrls: ['./landing.component.css']

})
export class LandingComponent implements OnInit {
    defaultTags: string[] = [
        'web',
        'illustration',
        'graphics',
        'ui',
        'design',
        'app',
        'iphone',
        'interface',
        'icon',
        'web-design'
    ];
    public isTasksForUserStory: boolean;
    public isUserStories: boolean = true;
    public inputUserStory = '';
    public isTasks: boolean;
    public tasksOnStory: any[] = [];
    public editedTasks: Array<any>;
    public ishide: boolean;
    public isModalActive: boolean = false;
    public isActive: boolean = false;
    public index: number;
    public isdisplay: boolean;
    public totalEstimate: number;
    public userEstimates = [];
    public signInForm: FormGroup;
    public isestimate: boolean;
    public tempdata: any;
    public isSuccessMsg: boolean;
    public successMsg: string;
    public messages: any;
    public tasksList: any[] = [];
    public toggleAddNewTask = false;
    @ViewChild('textarea') textarea: ElementRef;
    public logDetails = [] = [];
    public isChatBot = false;
    public inputText = '';
    public tags: HighlightTag[] = [];
    public findChoices: Function;
    public getChoiceLabel: Function;
    public searchTextLength = 0;
    public userStoryBox: boolean;
    public userStoryTaskList: boolean;
    public acceptClick = 0;
    public withEstimates: boolean;
    public tasksTableHeadings1: any = [
        '#',
        'Task Description',
        'Estimate',
        'MyEstimate'
    ];
    public tableHeadings: any = [
        '#',
        'Task Description',
        'Work Unit',
        'Language',
        'Complexity',
        'Estimate',
        'Footprints',
    ];
    public loading: any = {
        tasks: false,
    };
    showLoginPage: Boolean = false;
    showSignUpPage: Boolean = false;
    loggedInUser: string = '';

    constructor(private router: Router, private spinner: NgxSpinnerService, private apiService: ApiService, private formBuilder: FormBuilder) {
    }

    ngOnInit() {
        this.messages = DEFAULTMESSAGES;
        this.apiService.getCreatedDate();
        this.signInForm = this.formBuilder.group({
            description: [''],
            Estimate: ['']
        });
        // making sure the userstory is selected by default
        this.detailsBasedOn('userStory');
        this.refreshToken();
        // set logged in username
    }

    public routeTo() {
        let accessTokenTemp = window.localStorage.getItem('access_token');
        if (accessTokenTemp === 'Not Valid') {
            this.router.navigateByUrl('/signin');
        } else {
            this.router.navigateByUrl('/dashboard');
        }
    }

    /**
     * differntiate the userstory and task
     */

    public detailsBasedOn(key) {
        switch (key) {
            case 'task': {
                this.getTasks();
                this.isTasks = true;
                this.isUserStories = false;
                this.userStoryBox = false;
                this.withEstimates = false;
                break;
            }
            case 'userStory': {
                this.isTasks = false;
                this.isUserStories = true;
                this.userStoryBox = true;
                this.userStoryTaskList = false;
                this.inputUserStory = '';
                this.withEstimates = false;
                break;
            }
        }
    }

    /**
     * on userstory input
     * @param userStory
     * @param isNewStory
     */

    public onUserStoryInput(userStory, isNewStory) {
        if (this.isLoggedIn()) {
            this.spinner.show();
            this.apiService.storyToTasks({
                'story': userStory
            })
                .subscribe(
                    (res: any) => {
                        res.tasks.map((re) => {
                            return re.action = 'NC';
                        });
                        this.tasksOnStory = res.tasks;
                        this.editedTasks = res.tasks;
                        this.userStoryBox = false;
                        this.spinner.hide();
                    },
                    (err) => {
                        alert('An error occured...');
                        this.spinner.hide();
                    });
            this.isTasksForUserStory = true;
            this.userStoryTaskList = true;
            this.ishide = false;
        } else {
            this.showLoginPage = true;
        }
    }

    /**
     * clear user story
     */

    public clearUserStory() {
        this.tasksOnStory = [];
        this.inputUserStory = '';
        //  this.ishide = false;
    }

    /**
     * on adding a new task
     */

    public toggleModal(data?, i?) {
        this.isModalActive = !this.isModalActive;
        this.index = i;
        this.isdisplay = true;
        this.isestimate = false;
        this.tempdata = data;
    }

    /**
     * add a modal window
     */

    public addtoggleModal() {
        this.isModalActive = !this.isModalActive;
        this.signInForm.reset();
        this.isestimate = true;
        this.isdisplay = false;
        this.tempdata = [''];
    }

    /**
     * update the tasks
     */

    public updateStoryTask() {
        this.ishide = true;
        this.totalEstimate = 0;
        this.isTasksForUserStory = false;
        this.spinner.show();
        this.apiService.updateStoryTask({'tasks': this.editedTasks}).subscribe((res) => {
            if (res['message'] === 'success') {
                this.getEstimatesForUserStory();

            } else {
                this.isSuccessMsg = true;
                this.successMsg = res['message'];
            }
        });
    }

    /**
     * get the estimates for an user story
     */

    public getEstimatesForUserStory() {
        this.spinner.show();
        // this.apiService.generateEstimatesForUserStory(this.tasksOnStory[0].story_id)
        //     .subscribe((storyEstimates) => {
        this.spinner.hide();
        // console.log('estimates....', storyEstimates);
        this.isSuccessMsg = true;
        this.apiService.sendStoryId(JSON.stringify({'story_id': this.tasksOnStory[0].story_id}));
        this.tasksOnStory = [];
        this.spinner.show();
        this.apiService.getEstimatesForStoryId().subscribe((story: any) => {
            this.spinner.hide();
            // console.log('estimates....', story);
            this.isSuccessMsg = true;
            let tempStoryIndex;
            tempStoryIndex = this.tasksOnStory.findIndex((tskStory: any) => {
                return tskStory.id === JSON.parse(story).id;
            });
            //console.log('tempStoryIndex',tempStoryIndex);
            if (tempStoryIndex == -1) {
                this.tasksOnStory.push(JSON.parse(story));
            } else {
                this.tasksOnStory[tempStoryIndex].estimate = JSON.parse(story).estimate;
            }
            // console.log('message...', this.tasksOnStory);
            this.successMsg = 'Tasks updated successfully';
            this.totalOfAllEstimates();
            this.closeMsgAsync();
        });
        // })
    }

    /**
     * close the notification message
     */

    public closeMsgAsync() {
        setTimeout(() => {
            this.closeSuccessMsg();
        }, 3000);
    }

    /**
     * close the notification message
     */

    public closeSuccessMsg() {
        this.isSuccessMsg = false;
    }

    public totalOfAllEstimates() {
        // let totalEstimatesTemp:  number = 0;
        // console.log('user estimates...', this.userEstimates);
        this.totalEstimate = 0;
        this.tasksOnStory.forEach((tasks) => {
            this.totalEstimate += +tasks.estimate;
        });
        // this.acceptClick ++;
        // if(this.acceptClick > 1){
        //     this.acceptClick = 0;
        //     this.detailsBasedOn('userStory');
        // }else{

        // }


        // console.log('total', this.totalEstimate);

        this.spinner.show();
        this.apiService.updateUserEstimatesForTasks(this.tasksOnStory)
            .subscribe((res) => {
                this.spinner.hide();
                this.isSuccessMsg = true;
                this.successMsg = 'Success';
                this.closeMsgAsync();
            });
    }

    public reloadUserStory() {
        this.detailsBasedOn('userStory');
    }

    /**
     * edit the task description
     */

    public editTaskDiscription() {

        let editedTasksTemp = [];
        editedTasksTemp = this.editedTasks.slice();
        editedTasksTemp[this.index].task_desc = this.signInForm.value.description;
        editedTasksTemp[this.index].action = 'U';
        this.editedTasks = editedTasksTemp;
        this.tasksOnStory[this.index].task_desc = this.signInForm.value.description;
        this.isSuccessMsg = true;
        this.successMsg = 'Task description edited';
        this.closeMsgAsync();
        this.isModalActive = !this.isModalActive;
    }

    /**
     * add a new task description
     */

    public addTaskDiscription() {

        this.tasksOnStory.push({task_desc: this.signInForm.value.description, action: 'C', 'story_id': this.tasksOnStory[0].story_id});
        this.isSuccessMsg = true;
        this.successMsg = 'New Task description added';
        this.closeMsgAsync();
        this.isModalActive = !this.isModalActive;
    }

    /**
     * delete the task description
     */

    public deleteTaskDescription() {
        this.editedTasks[this.index].action = 'X';
        let tempStories = this.tasksOnStory.slice();
        tempStories.splice(this.index, 1);
        this.tasksOnStory = tempStories;
        this.isSuccessMsg = true;
        this.successMsg = 'Task description deleted';
        this.closeMsgAsync();
        this.isActive = !this.isActive;
    }

    /**
     * get tasks based on login user
     */

    public getTasks(): void {
        this.loading.tasks = true;
        this.spinner.show();
        this.apiService.getTasksByUser()
            .subscribe((res: any[]) => {
                    this.spinner.hide();
                    this.loading.tasks = false;
                    this.tasksList = res;
                    this.tasksList.forEach((task) => {
                        if (task.mean_estimate) {
                            let tempArr = (Number(task.mean_estimate).toFixed(2)).split('.');
                            tempArr[0] = this.prependZero(tempArr[0]);
                            task.mean_estimate = tempArr.join(':');
                            //console.log(task.mean_estimate);
                        }
                    });
                },
                (err) => {
                    this.spinner.hide();
                }
            );
    }

    public prependZero(number) {
        if (number <= 9) {
            return '0' + number;
        } else {
            return number;
        }
    }

    /**
     * updateTask
     * @param task
     */

    public updateEstimate(task: any) {
        //console.log(task);
        this.apiService.getEstimateByTaskId(task['id'])
            .subscribe(estimate => {
                this.apiService.updateTask(task, estimate).subscribe(resp => {
                    this.getTasks();
                });
            });
    }

    /**
     * allow user to add a new task
     */

    public showAddNewTask() {
        this.toggleAddNewTask = this.toggleAddNewTask === true ? false : true;
        setTimeout(() => {
            this.textarea.nativeElement.scrollIntoView();
            this.textarea.nativeElement.focus();
        }, 200);
    }

    refreshToken() {
        console.log('called')
        this.apiService.getRefreshToken().subscribe(res => {
            window.localStorage.setItem('access_token', res['access_token']);
            localStorage.setItem('bearer_token', res['access_token']);
        });
    }

    /**
     * on adding the new task
     * @param task
     * @param isNewInput
     */

    public onTaskInput(task: string, isNewInput: boolean) {
        if (this.isLoggedIn()) {
            if (isNewInput) {
                this.logDetails = [];
            }
            this.apiService.getResponseFromDialogFlow(task).then(
                (responce) => {
                    const speechTemp = responce['result']['fulfillment']['data']['google']['richResponse']['items'][0]['simpleResponse']['textToSpeech'];
                    this.isChatBot = true;
                    const dateTemp = new Date();
                    let secondsDiffernce;
                    if (this.logDetails.length !== 0) {
                        secondsDiffernce = this.getSecondsDifference();
                    } else {
                        secondsDiffernce = 0;
                    }
                    this.logDetails.push({
                        'SPEECH': speechTemp,
                        'USERTYPE': 'chatbot',
                        'TIMESTAMP': dateTemp,
                        'SECONDS DIFFERENCE': secondsDiffernce
                    });

                    if (responce['result']['fulfillment']['data']['google']['expectUserResponse']) {
                        this.isChatBot = true;
                        this.messages = {'speech': speechTemp, 'userType': 'chatbot', 'timestamp': dateTemp.toLocaleTimeString()};
                    } else {
                        const speechType = typeof (speechTemp);
                        if (speechType === 'object') {
                            this.messages = {
                                'speech': 'Estimates are running.....',
                                'userType': 'chatbot',
                                'timestamp': dateTemp.toLocaleTimeString()
                            };
                            this.apiService.generateEstimatesForTask(this.inputText, speechTemp).subscribe((estimates) => {
                                // console.log('Estimates:', estimates);
                                this.getTasks();
                                this.isChatBot = false;
                                this.withEstimates = true;
                                this.isTasks = false;
                                this.inputText = '';
                            });
                        } else {
                            this.messages = {'speech': speechTemp, 'userType': 'chatbot', 'timestamp': dateTemp.toLocaleTimeString()};
                            setTimeout(() => {
                                this.isChatBot = false;
                            }, 2000);
                            this.inputText = '';
                        }
                    }
                }, (error) => {
                    //console.log('Error occured.......', error);
                    const dateTemp = new Date().toLocaleTimeString();
                    this.messages = {'speech': error.message, 'userType': 'chatbot', 'timestamp': dateTemp};
                });
        } else {
            this.showLoginPage = true;
        }
    }

    /**
     * get the difference of time in seconds
     */

    public getSecondsDifference() {
        const logDetailsCopy = this.logDetails.slice();
        const startDate = new Date(logDetailsCopy.pop().TIMESTAMP);
        const endDate = new Date();
        const secondsDiff = ((endDate.getTime() - startDate.getTime()) / 1000);
        return secondsDiff;
    }

    /**
     * hide inputbox
     */

    public cancelAddTaskInputBox() {
        this.toggleAddNewTask = false;
        this.inputText = '';
        this.tags = [];
    }

    /**
     * on new user input
     * @param userInput
     */

    public onUserQuery(userInput: string) {
        let secondsDiffernce;
        if (this.logDetails.length !== 0) {
            secondsDiffernce = this.getSecondsDifference();
        } else {
            secondsDiffernce = 0;
        }
        this.logDetails.push({'SPEECH': userInput, 'USERTYPE': 'user', 'TIMESTAMP': new Date()});
        this.messages = {'speech': userInput, 'userType': 'external', 'timestamp': new Date().toLocaleTimeString()};
        this.onTaskInput(userInput, false);
    }

    public onUserEstimate(userEstimate, key) {
        this.tasksOnStory[key].user_estimates = +userEstimate.innerText;
        // console.log('on my estimate....', this.tasksOnStory);
    }

    /**
     * toggle the modal window
     * @param id
     */

    public toggle(id?) {
        this.index = id;
        this.isActive = !this.isActive;
    }

    /**
     * to open chatbot directly by clickng on chatbot icon
     */
    public openChatBot() {
        this.isChatBot = true;
    }

    clickedClose() {
        this.showLoginPage = false;
    }

    showSignUpPageEvent() {
        this.showLoginPage = false;
        this.showSignUpPage = true;
    }

    closeSignUp() {
        this.showSignUpPage = false;
    }

    openLogin() {
        this.showLoginPage = true;
        this.showSignUpPage = false;
    }

    isLoggedIn() {
        if (localStorage.getItem('login') === 'true') {
            return true;
        } else {
            return false;
        }
    }

    getUsername() {
        return localStorage.getItem('username');
    }

    logout() {
        localStorage.clear();
    }
}
