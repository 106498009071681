import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { TextInputAutocompleteModule } from 'angular-text-input-autocomplete';
import { TextInputHighlightModule } from 'angular-text-input-highlight';

import { RatingModule } from 'ngx-rating';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import { NgxPaginationModule } from 'ngx-pagination';

import { AppRoutingModule } from './app-routing.module';
import { CommonsModule } from './commons/commons.module';

import { ApiService } from './services/api.service';
import { AppComponent } from './app.component';
import { NavigationComponent } from './navigation/navigation.component';
import { BottomNavigationComponent } from './bottom-navigation/bottom-navigation.component';
import { LandingComponent } from './landing/landing.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { FeedbackModalComponent } from './feedback-modal/feedback-modal.component';
import { ChatbotComponent } from './chatbot/chatbot.component';
import { SignUpComponent } from './sign-up/sign-up.component';

const config: SocketIoConfig = { url: 'https://a7a6367d1827.ngrok.io/wss/generate-estimate', options: {} };


@NgModule({
    declarations: [
        AppComponent,
        NavigationComponent,
        BottomNavigationComponent,
        LandingComponent,
        LoginComponent,
        DashboardComponent,
        FeedbackModalComponent,
        ChatbotComponent,
        SignUpComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        FormsModule,
        HttpClientModule,
        TextInputAutocompleteModule,
        TextInputHighlightModule,
        RatingModule,
        AppRoutingModule,
        CommonsModule,
        ReactiveFormsModule,
        NgxPaginationModule,
        NgxSpinnerModule,
        SocketIoModule.forRoot(config)
    ],
    providers: [
        ApiService
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
