import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NgForm, FormGroup, FormBuilder, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {ApiService} from '../services/api.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    @Input() showHeaderFooter = true;
    @Output() closeEvent = new EventEmitter();
    @Output() signUpEvent = new EventEmitter();
    public loginForm: FormGroup;
    public submitted = false;
    public isErrorMsg = false;
    public isSuccessMsg = false;

    constructor(
        private apiService: ApiService,
        private formBuilder: FormBuilder,
        private router: Router,
        private spinner: NgxSpinnerService
    ) {
    }

    ngOnInit() {
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required]]
        });
    }

    get f() {
        return this.loginForm.controls;
    }

    /**
     * on user login
     */

    public onLogin() {
        this.submitted = true;
        if (this.loginForm.invalid) {
            return;
        }
        this.spinner.show();
        this.apiService.login(this.loginForm.value)
            .subscribe(
                (res) => {
                    this.spinner.hide();
                    console.log(res);
                    if (res['access_token']) {
                        // this.isUserAuthenticated = true;
                        this.apiService.setUserAuthenticated(true);
                        this.isSuccessMsg = true;
                        setTimeout(() => {
                            this.isSuccessMsg = false;
                            //   this.router.navigate(['/dashboard']);
                            this.closeClick();
                            window.localStorage.setItem('access_token', res['access_token']);
                            localStorage.setItem('bearer_token', res['access_token']);
                            localStorage.setItem('refreshToken', res['refresh_token']);
                            localStorage.setItem('login', 'true');
                            localStorage.setItem('username', res['name']);
                        }, 2000);
                    }
                },
                (err) => {
                    this.spinner.hide();
                    this.isErrorMsg = true;
                    setTimeout(() => {
                        this.isErrorMsg = false;
                    }, 4000);
                }
            );
    }

    /**
     * close error message
     */

    public closeErrorMsg() {
        this.isErrorMsg = false;
    }

    /**
     * close success message
     */

    public closeSuccessMsg() {
        this.isSuccessMsg = false;
    }

    closeClick() {
        this.closeEvent.emit();
    }

    signUpClick() {
        this.signUpEvent.emit();
    }
}
