import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule  }   from '@angular/forms';

import { RadioButtonsComponent } from './components/radio-buttons/radio-buttons.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule
  ],
  declarations: [
    RadioButtonsComponent
  ],
  exports:[
    RadioButtonsComponent
  ]
})
export class CommonsModule { }
