import {Router} from '@angular/router';
import {Injectable} from '@angular/core';

import {Observable, of} from 'rxjs';
import {Observer} from 'rxjs';
import {filter, map, catchError} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpEventType, HttpRequest, HttpErrorResponse} from '@angular/common/http';
import {environment} from '../../environments/environment';

// @ts-ignore
import {ApiAiClient} from 'api-ai-javascript';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import {Socket} from 'ngx-socket-io';
import {RequestOptions} from '@angular/http';


const SERVER_URL = 'http://localhost:8080';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
    providedIn: 'root'
})
export class ApiService {

    readonly _client = new ApiAiClient({accessToken: environment.clientAccessToken});

    static UNKNOWN_PROJECT = -100;
    private baseUrl: string = environment.apiUrl;
    private baseUrl1: string = environment.apiUrl1;
    private data: any = {
        userInfo: null,
        activities: null,
        proxies: null,
        taskcomplexities: null,
        languages: null,
        tasks: []
    };

    public isUserAuthenticated: boolean;
    public userEmail: string;

    constructor(private http: HttpClient, private router: Router, private socket: Socket) {
        this.isUserAuthenticated = false;

        this.socket.on('connect', () => {
            console.log('connection established....');
        });
    }

    getFromCache(k: string) {
        return this.data[k];
    }

    ifCached(k: string, p) {
        if (this.data[k]) {
            // console.log( "not calling api" );
            return of(this.data[k]);
        } else {
            // console.log( "calling api" );
            return p.pipe(map(r => {
                this.data[k] = r;
                return r;
            }), catchError(err => of(null)));
        }
    }

    clearCached() {
        Object.keys(this.data).map(k => {
            this.data[k] = null;
        });
    }

    login(loginCredentials) {
        this.userEmail = loginCredentials.email;
        return this.http.post(this.baseUrl + '/login', loginCredentials);
    }

    storyToTasks(obj) {
        const httpOptions = this.getOptions();
        return this.http.post(this.baseUrl1 + '/tasks-from-story', obj, httpOptions);
    }

    updateStoryTask(obj) {
        const httpOptions = this.getOptions();
        return this.http.post(this.baseUrl1 + '/update-story-tasks', obj, httpOptions);
    }

    // setUserStatus(){
    //     this.isUserAuthenticated = true;
    // }

    isLoggedIn() {
        return this.isUserAuthenticated;
        // return !!this.data.userInfo; // if userInfo exists, consider user logged in
    }

    setUserAuthenticated(isAuthenticated) {
        this.isUserAuthenticated = isAuthenticated;
        // return !!this.data.userInfo; // if userInfo exists, consider user logged in
    }

    logout() {
        this.clearCached();
    }

    findByEmail(email: string) {
        return this.ifCached(
            'userInfo',
            this.http.get(this.baseUrl + '/userprofile/search/findByEmail?email=' + email)
        );
    }

    getActivities() {
        return this.ifCached(
            'activities',
            this.http.get(this.baseUrl + '/activities')
        );
    }

    getUserRoles() {
        return this.http.get(this.baseUrl + '/web/get-roles');
    }

    getProxies() {
        return this.ifCached(
            'proxies',
            this.http.get(this.baseUrl + '/proxies')
        );
    }

    getTaskComplexities() {
        return this.ifCached(
            'taskcomplexities',
            this.http.get(this.baseUrl + '/taskcomplexities')
        );
    }

    getLanguages() {
        const httpOptions = this.getOptions();
        return this.http.get(this.baseUrl + '/languages', httpOptions);
    }

    getRefreshToken() {
        const httpOptions = this.getRefreshOptions();
        return this.http.post(this.baseUrl + '/refresh', {}, httpOptions);
    }

    getEstimateByTaskId(taskId: number) {
        const httpOptions = this.getOptions();
        return this.http.get(this.baseUrl + '/get-estimate/' + taskId, httpOptions);
    }

    getTasksByUser(email?: string) {
        // return this.ifCached(
        //     "tasks",
        //     this.http.get(this.baseUrl + "/user-project-tasks/" + projectId)
        // );
        const httpOptions = this.getOptions();
        return this.http.get(`${this.baseUrl}/web` + '/get-tasks-for-user/' + 'contact@kornerstoneanalytics.com', httpOptions);
    }

    addTask(task: any) {
        const httpOptions = this.getOptions();
        return this.http.post(this.baseUrl + '/add-task', {
            'data': [
                {
                    'table': 'MNEME_TASKS',
                    'column': [
                        'NAME', 'PROJECT_ID', 'JSON_DATA', 'TYPE',
                        'STATUS', 'CREATED_DATE', 'UPDATED_DATE', 'WORK_UNIT',
                        'EMAIL', 'COMPLEXITY', 'LANG', 'ACTUAL',
                        'LANG_PROFICIENCY', 'DOMAIN_PROFICIENCY', 'TASK_EXPERIENCE', 'WORK_EXPERIENCE'
                    ],
                    'row': [
                        `${task.description}`,
                        ApiService.UNKNOWN_PROJECT,                                    // "0",
                        '{}',
                        `${task.activity.name}`,                 //  "Modification Minor(activities)",
                        'Saved',
                        (new Date()).getTime(),                  //  1534962600000,
                        (new Date()).toISOString().slice(0, 16), //  "2018-08-23T14:33",
                        `${task.proxy.name}`,                    //  "API Call (proxies)",
                        `${this.data.userInfo.email}`,           //  "demo@demo.com",
                        `${task.taskComplexity.name}`,           //  "Nominal(taskcomplexities)",
                        `${task.language.name}`,                 //  "Angular JS(languages)",
                        null,
                        'Nominal',
                        'Nominal',
                        'Nominal',
                        'Nominal'
                    ]
                }
            ]
        }, httpOptions);
    }

    updateTask(task: any, estimate: any) {
        const httpOptions = this.getOptions();
        return this.http.post(this.baseUrl + '/update-task/', {
            'data': [
                {
                    'table': 'MNEME_TASKS',
                    'taskid': task.id,
                    'column': [
                        'NAME', 'PROJECT_ID', 'JSON_DATA', 'TYPE',
                        'STATUS', 'UPDATED_DATE', 'WORK_UNIT', 'COMPLEXITY',
                        'LANG', 'ACTUAL', 'LOW_ESTIMATE', 'HIGH_ESTIMATE',
                        'MYESTIMATE', 'START_DATE', 'LANG_PROFICIENCY', 'DOMAIN_PROFICIENCY',
                        'TASK_EXPERIENCE', 'WORK_EXPERIENCE'
                    ],
                    'row': [
                        `${task.name}`, // "Arpan Demo",
                        ApiService.UNKNOWN_PROJECT,
                        '{}',
                        `${task.activityType.name}`,                 //  "Modification Minor(activities)",
                        'Saved',
                        (new Date()).toISOString().slice(0, 16), //  "2018-08-23T14:33",
                        `${task.proxy.name}`,                    //  "API Call (proxies)",
                        `${task.complexity.name}`,           //  "Nominal(taskcomplexities)",
                        `${task.language.name}`,                 //  "Angular JS(languages)",
                        null,
                        `${estimate.lowEstimate}`,// "20",
                        `${estimate.highEstimate}`,// "",
                        `${estimate.medianEstimate}`,// "21",
                        (new Date()).toDateString().slice(4), // "Aug 23 2018",
                        'Low',
                        'Nominal',
                        'Nominal',
                        'High'
                    ]
                }
            ]
        }, httpOptions);
    }

    // public submitTask(inputVal) {
    //     return this.http.post(`https://960b8008.ngrok.io/getuserdata`, {
    //         "text": inputVal
    //     });
    // }


    public signUp(userDetails: {}) {
        return this.http.post(this.baseUrl + '/register', userDetails);
    }


    public getEstimates(params) {
        // return this.http.post(this.baseUrl +)
    }

    public getResponseFromDialogFlow(msg) {
        return this._client.textRequest(msg);
    }

    public getCreatedDate() {
        let tempDate = new Date();
        let dateString =
            tempDate.getUTCFullYear() + '/' +
            ('0' + (tempDate.getUTCMonth() + 1)).slice(-2) + '/' +
            ('0' + tempDate.getUTCDate()).slice(-2) + ' ' +
            ('0' + tempDate.getUTCHours()).slice(-2) + ':' +
            ('0' + tempDate.getUTCMinutes()).slice(-2);

        console.log(dateString);
        return dateString;
    }

    public generateEstimatesForTask(taskDescription: string, taskDetails) {
        // console.log('task details', taskDetails, taskDescription);
        let transformedTaskDetails = {
            ...taskDetails, 'bespoke_id': 'web', 'created_date': this.getCreatedDate(), 'email': 'contact@kornerstoneanalytics.com',
            task_description: taskDescription
        };

        console.log('transformed......', transformedTaskDetails);
        const httpOptions = this.getOptions();
        return this.http.post(this.baseUrl + '/web/generate-estimate-for-task', transformedTaskDetails, httpOptions);
    }

    public exportAsExcelFile(dataToExport: any[], excelFileName: string): void {
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(dataToExport);
        console.log('worksheet', worksheet);
        const workbook: XLSX.WorkBook = {Sheets: {'data': worksheet}, SheetNames: ['data']};
        const excelBuffer: any = XLSX.write(workbook, {bookType: 'xlsx', type: 'array'});
        //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE
        });
        FileSaver.saveAs(data, fileName + new Date().toLocaleTimeString() + EXCEL_EXTENSION);
    }

    public generateEstimatesForUserStory(storyId) {
        return this.http.post(this.baseUrl1 + '/generate-estimates-for-user-story', {user_story_id: storyId});
    }

    public updateUserEstimatesForTasks(userEstimates) {
        const httpOptions = this.getOptions();
        return this, this.http.put(this.baseUrl1 + '/generate-estimates-for-user-story', {'estimates': userEstimates}, httpOptions);
    }

    public sendStoryId(storyId) {
        this.socket.emit('message', storyId);
    }

    public getEstimatesForStoryId() {
        return this.socket
            .fromEvent('message');

    }

    getOptions() {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + window.localStorage.getItem('bearer_token')
            })
        };
    }

    getRefreshOptions() {
        return {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + window.localStorage.getItem('refreshToken')
            })
        };
    }
}
