import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-chatbot',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.css']
})
export class ChatbotComponent implements OnInit {


  @Output() additionalParams = new EventEmitter();
  @Output('userQuery') userQuery = new EventEmitter();
  @Input()
  public set messages(msgs) {
    if (msgs) {
      this.tranformMessages(msgs);
    }
  }
  public get messages() {
    return this._messages;
  }
  public userInput: string;
  public _messages: any[] = [];
  public msgExtraParamters: Array<{}> = [];
  public isChatBot = false;

  constructor() { }

  ngOnInit() {
  }

  /**
   * tranform the messages need to display to user
   * @param msgs
   */

  public tranformMessages(msgs: {}) {
    let msgTemp = [];
    let paramsTemp = [];
    paramsTemp = [];
    if (msgs['speech'].indexOf('::') !== -1) {
      msgTemp = msgs['speech'].split('::');
      msgTemp[1].split(',').forEach(parameter => {
        paramsTemp.push(parameter);
      });
      msgs['speech'] = msgTemp[0];
      this.msgExtraParamters[msgTemp[0]] = paramsTemp;
    }
    this.isChatBot = true;
    this._messages.push(msgs);
  }

  /**
   * on user input
   * @param userVal
   */

  public onUserInput(userVal: NgForm) {
    if (this.userInput !== '') {
      this.userQuery.emit(this.userInput);
      userVal.form.reset();
    }
  }

  /**
   * on additional Params input
   * @param param
   */

  public onAdditionalParamInput(param: string) {
    this.userQuery.emit(param);
  }

  /**
   * close modal window
   */

  public closeModalWindow() {
    this.isChatBot = false;
  }
}
