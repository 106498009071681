import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { HighlightTag } from 'angular-text-input-highlight';
import { NgxSpinnerService } from 'ngx-spinner';
import { saveAs } from 'file-saver';

import { ApiService } from '../services/api.service';
import { DEFAULTMESSAGES } from '../models/messages.model';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css'],
    encapsulation: ViewEncapsulation.None,
})

export class DashboardComponent implements OnInit {

    public signInForm: FormGroup;
    public isestimate: boolean;
    @ViewChild('textarea') textarea: ElementRef;
    public toggleAddNewTask = false;
    public isTasksForUserStory: boolean;
    public ishide: boolean;
    public isSuccessMsg: boolean;
    public successMsg: string;
    public inputText = '';
    public inputUserStory = '';
    public findChoices: Function;
    public getChoiceLabel: Function;

    public searchTextLength = 0;
    public tags: HighlightTag[] = [];
    public discription: string;
    public Estimate: string;
    public editedTasks: Array<any>;
    public isModalActive: boolean = false;
    public isActive: boolean = false;
    public tasksList: any[] = [];
    public tasksOnStory: any[] = [];
    public tempdata: any;

    public messages: any;
    public isChatBot = false;
    public logDetails = [] = [];
    public isTasks = true;
    public isUserStories: boolean;
    public ismodel1: boolean;
    public index: number;
    public isdisplay: boolean;
    public totalEstimate: number;
    public userEstimates = [];

    private userSelected: any = {
        activity: '',
        proxy: '',
        taskComplexity: '',
        language: ''
    };

    public loading: any = {
        tasks: false,
    };

    public defaultTags: string[] = [
        'web',
        'illustration',
        'graphics',
        'ui',
        'design',
        'app',
        'iphone',
        'interface',
        'icon',
        'web-design'
    ];

    public tableHeadings: any = [
        '#',
        'Task Description',
        'Work Unit',
        'Language',
        'Complexity',
        'Estimate',
        'Footprints',
    ];

    public tasksTableHeadings: any = [
        '#',
        'Task Description',
        'Actions'
    ];

    public tasksTableHeadings1: any = [
        '#',
        'Task Description',
        'Estimate',
        'MyEstimate'
    ];

    constructor(private apiService: ApiService, private spinner: NgxSpinnerService, private formBuilder: FormBuilder, ) { }

    ngOnInit() {
        this.messages = DEFAULTMESSAGES;
        this.getTasks();
        this.apiService.getCreatedDate();
        this.signInForm = this.formBuilder.group({
            description: [''],
            Estimate: ['']
        });
    }


    /**
     * get tasks based on login user
     */

    public getTasks(): void {
        this.loading.tasks = true;
        this.spinner.show();
        this.apiService.getTasksByUser()
            .subscribe((res: any[]) => {
                this.spinner.hide();
                this.loading.tasks = false;
                this.tasksList = res;
            },
                (err) => {
                    this.spinner.hide();
                }
            );
    }

    /**
     * check the input lenght
     */

    public getInputLength() {
        return this.inputText.length;
    }

    /**
     * allow user to add a new task
     */

    public showAddNewTask() {
        this.toggleAddNewTask = this.toggleAddNewTask === true ? false : true;
        setTimeout(() => {
            this.textarea.nativeElement.scrollIntoView();
            this.textarea.nativeElement.focus();
        }, 200);
    }

    /**
     * on adding a new task
     */

    public toggleModal(data?, i?) {
        this.isModalActive = !this.isModalActive;
        this.index = i;
        this.isdisplay = true;
        this.isestimate = false;
        this.tempdata = data;
    }

    /**
     * toggle the modal window 
     * @param id 
     */

    public toggle(id?) {
        this.index = id;
        this.isActive = !this.isActive;
    }

    /**
     * add a modal window 
     */

    public addtoggleModal() {
        this.isModalActive = !this.isModalActive;
        this.signInForm.reset();
        this.isestimate = true;
        this.isdisplay = false;
        this.tempdata = [''];
    }

    /**
     * add a task
     */

    public addTask() {
        this.apiService.addTask({
            description: this.inputText,
            activity: this.tags.filter(v => v.data.type === 'activity')[0].data.value,
            proxy: this.tags.filter(v => v.data.type === 'proxy')[0].data.value,
            taskComplexity: this.tags.filter(v => v.data.type === 'taskComplexity')[0].data.value,
            language: this.tags.filter(v => v.data.type === 'language')[0].data.value,
        })
            .subscribe(task => {
                this.cancelAddTaskInputBox();
                this.apiService.getEstimateByTaskId(task['id'])
                    .subscribe(estimate => {
                        this.apiService.updateTask(task, estimate).subscribe(resp => {
                            this.getTasks();
                        });
                    });
            });
    }

    /**
     * hide inputbox
     */

    public cancelAddTaskInputBox() {
        this.toggleAddNewTask = false;
        this.inputText = '';
        this.tags = [];
    }

    /**
     * differntiate the userstory and task
     */

    public detailsBasedOn(key) {
        switch (key) {
            case 'task': {
                this.isTasks = true;
                this.isUserStories = false;
                break;
            }
            case 'userStory': {
                this.isTasks = false;
                this.isUserStories = true;
                break;
            }
        }
    }

    /**
     * updateTask
     * @param task
     */

    public updateEstimate(task: any) {
        this.apiService.getEstimateByTaskId(task['id'])
            .subscribe(estimate => {
                this.apiService.updateTask(task, estimate).subscribe(resp => {
                    this.getTasks();
                });
            });
    }

    /**
     * on new user input
     * @param userInput
     */

    public onUserQuery(userInput: string) {
        let secondsDiffernce;
        if (this.logDetails.length !== 0) {
            secondsDiffernce = this.getSecondsDifference();
        } else {
            secondsDiffernce = 0;
        }
        this.logDetails.push({ 'SPEECH': userInput, 'USERTYPE': 'user', 'TIMESTAMP': new Date() });
        this.messages = { 'speech': userInput, 'userType': 'external', 'timestamp': new Date().toLocaleTimeString() };
        this.onTaskInput(userInput, false);
    }

    /**
     * on adding the new task
     * @param task
     * @param isNewInput
     */

    public onTaskInput(task: string, isNewInput: boolean) {
        if (isNewInput) {
            this.logDetails = [];
        }
        this.apiService.getResponseFromDialogFlow(task).then(
            (responce) => {
                const speechTemp = responce['result']['fulfillment']['data']['google']['richResponse']['items'][0]['simpleResponse']['textToSpeech'];
                this.isChatBot = true;
                const dateTemp = new Date();
                let secondsDiffernce;
                if (this.logDetails.length !== 0) {
                    secondsDiffernce = this.getSecondsDifference();
                } else {
                    secondsDiffernce = 0;
                }
                this.logDetails.push({ 'SPEECH': speechTemp, 'USERTYPE': 'chatbot', 'TIMESTAMP': dateTemp, 'SECONDS DIFFERENCE': secondsDiffernce });

                if (responce['result']['fulfillment']['data']['google']['expectUserResponse']) {
                    this.isChatBot = true;
                    this.messages = { 'speech': speechTemp, 'userType': 'chatbot', 'timestamp': dateTemp.toLocaleTimeString() };
                } else {
                    const speechType = typeof (speechTemp);
                    if (speechType === 'object') {
                        this.messages = { 'speech': 'Estimates are running.....', 'userType': 'chatbot', 'timestamp': dateTemp.toLocaleTimeString() };
                        this.apiService.generateEstimatesForTask(this.inputText, speechTemp).subscribe((estimates) => {
                            // console.log('Estimates:', estimates);
                            this.getTasks();
                            this.isChatBot = false;
                            this.inputText = '';
                        });
                    } else {
                        this.messages = { 'speech': speechTemp, 'userType': 'chatbot', 'timestamp': dateTemp.toLocaleTimeString() };
                        setTimeout(() => {
                            this.isChatBot = false;
                        }, 2000);
                        this.inputText = '';
                    }
                }
            }, (error) => {
                console.log('Error occured.......', error);
                const dateTemp = new Date().toLocaleTimeString();
                this.messages = { 'speech': error.message, 'userType': 'chatbot', 'timestamp': dateTemp };
            });
    }

    /**
     * on userstory input
     * @param userStory
     * @param isNewStory
     */

    public onUserStoryInput(userStory, isNewStory) {
        this.spinner.show();
        this.apiService.storyToTasks({
            "story": userStory
        })
            .subscribe(
                (res: any) => {
                    res.tasks.map((re) => {
                        return re.action = "NC";
                    })
                    this.tasksOnStory = res.tasks;
                    this.editedTasks = res.tasks;
                    this.spinner.hide();
                },
                (err) => {
                    alert('An error occured...');
                    this.spinner.hide();
                })
        this.isTasksForUserStory = true;
        this.ishide = false;
    }

    /**
     * add a new task description
     */

    public addTaskDiscription() {

        this.tasksOnStory.push({ task_desc: this.signInForm.value.description, action: "C", "story_id": this.tasksOnStory[0].story_id })
        this.isSuccessMsg = true;
        this.successMsg = 'New Task description added';
        this.closeMsgAsync();
        this.isModalActive = !this.isModalActive;
    }

    /**
     * edit the task description
     */

    public editTaskDiscription() {

        let editedTasksTemp = [];
        editedTasksTemp = this.editedTasks.slice();
        editedTasksTemp[this.index].task_desc = this.signInForm.value.description;
        editedTasksTemp[this.index].action = "U";
        this.editedTasks = editedTasksTemp;
        this.tasksOnStory[this.index].task_desc = this.signInForm.value.description;
        this.isSuccessMsg = true;
        this.successMsg = 'Task description edited';
        this.closeMsgAsync();
        this.isModalActive = !this.isModalActive;
    }

    /**
     * delete the task description
     */

    public deleteTaskDescription() {
        this.editedTasks[this.index].action = "X";
        let tempStories = this.tasksOnStory.slice();
        tempStories.splice(this.index, 1);
        this.tasksOnStory = tempStories;
        this.isSuccessMsg = true;
        this.successMsg = 'Task description deleted';
        this.closeMsgAsync();
        this.isActive = !this.isActive;
    }

    /**
     * update the tasks 
     */

    public updateStoryTask() {
        this.ishide = true;
        this.totalEstimate = 0;
        this.isTasksForUserStory = false;
        this.spinner.show();
        this.apiService.updateStoryTask({ "tasks": this.editedTasks }).subscribe((res) => {
            if (res['message'] === 'success') {
                this.getEstimatesForUserStory();

            } else {
                this.isSuccessMsg = true;
                this.successMsg = res['message'];
            }
        })
    }

    /**
     * get the estimates for an user story
     */

    public getEstimatesForUserStory() {
        this.spinner.show();
        // this.apiService.generateEstimatesForUserStory(this.tasksOnStory[0].story_id)
        //     .subscribe((storyEstimates) => {
                this.spinner.hide();
                // console.log('estimates....', storyEstimates);
                this.isSuccessMsg = true;
                this.apiService.sendStoryId(JSON.stringify({"story_id":this.tasksOnStory[0].story_id}))
                this.tasksOnStory = [];
                this.spinner.show()
                this.apiService.getEstimatesForStoryId().subscribe((story: any)=>{
                    this.spinner.hide();
                    // console.log('estimates....', story);
                    this.isSuccessMsg = true;
                    let tempStoryIndex;
                    tempStoryIndex = this.tasksOnStory.findIndex((tskStory: any)=>{
                       return tskStory.id === JSON.parse(story).id
                    })
                    console.log('tempStoryIndex',tempStoryIndex);
                    if(tempStoryIndex == -1) {
                        this.tasksOnStory.push(JSON.parse(story));
                    } else {
                        this.tasksOnStory[tempStoryIndex].estimate = JSON.parse(story).estimate;
                    }
                        // console.log('message...', this.tasksOnStory);
                        this.successMsg = "Tasks updated successfully";
                        this.closeMsgAsync();
                    }); 
            // })
    }


    public onUserEstimate(userEstimate, key) {
        this.tasksOnStory[key].user_estimates = +userEstimate.innerText;
        // console.log('on my estimate....', this.tasksOnStory);
    }

    public totalOfAllEstimates() {
        // let totalEstimatesTemp:  number = 0;
        // console.log('user estimates...', this.userEstimates);
        this.totalEstimate = 0;
        this.tasksOnStory.forEach((tasks) => {
            this.totalEstimate += +tasks.estimate;
        })

        // console.log('total', this.totalEstimate);

        this.spinner.show();
        this.apiService.updateUserEstimatesForTasks(this.tasksOnStory)
            .subscribe((res) => {
                this.spinner.hide();
                this.isSuccessMsg = true;
                this.successMsg = "Success";
                this.closeMsgAsync();
        })
    }

    /**
     * close the notification message
     */

    public closeMsgAsync() {
        setTimeout(() => {
            this.closeSuccessMsg();
        }, 3000);
    }

    /**
     * close the notification message
     */

    public closeSuccessMsg() {
        this.isSuccessMsg = false;
    }

    /**
     * get the difference of time in seconds 
     */

    public getSecondsDifference() {
        const logDetailsCopy = this.logDetails.slice();
        const startDate = new Date(logDetailsCopy.pop().TIMESTAMP);
        const endDate = new Date();
        const secondsDiff = ((endDate.getTime() - startDate.getTime()) / 1000);
        return secondsDiff;
    }

    /**
     * clear user story
     */

     public clearUserStory() {
         this.tasksOnStory = [];
         this.inputUserStory = '';
        //  this.ishide = false;
     }
}
