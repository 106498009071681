import { Component, OnInit, SimpleChanges, Input } from '@angular/core';
import { ApiService } from '../services/api.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.css']
})
export class NavigationComponent implements OnInit {
    public email: string;
    constructor(private apiService: ApiService, private router: Router, private activatedRoute: ActivatedRoute) {
    }
    ngOnInit() {
    //   console.log(window.location.href
    }

    get isLoggedIn() {
        let accessTokenTemp = window.localStorage.getItem('access_token')
        this.email = this.apiService.userEmail;
        if (accessTokenTemp === null || accessTokenTemp === 'Not Valid') {
            return false;
        } else {
            return true;
        }
    }

    public onLogOut() {
        window.localStorage.setItem('access_token', 'Not Valid');
    }
}
