import { Component, OnInit, Input } from '@angular/core';

@Component({
  	selector: 'app-feedback-modal',
  	templateUrl: './feedback-modal.component.html',
  	styleUrls: ['./feedback-modal.component.css']
})
export class FeedbackModalComponent implements OnInit {

	@Input() active : boolean = false;

	inputText : string = '';
	starsCount : number = 3;

  	constructor() { }

  	ngOnInit() {
  	}

	send () {

	}

}
